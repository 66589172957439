<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 流程设计</h4>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <div class="top">
        <el-button @click="$router.back()">返回</el-button>
        <el-button @click="save" type="primary">保存</el-button>
      </div>
      <process-design ref="processDesign"></process-design>
    </div>
  </div>
</template>

<script>
import ProcessDesign from '@/components/formProcessDesign'
import Condition from "@/components/formProcessDesign/components/viewCommon/process/config/ConditionNodeConfigNew";
import pro from "@/views/wfl/act/pro";

export default {
  name: "process",
  data() {
    return {
      form: JSON.parse(sessionStorage.getItem('formData')),
      formList: []
    }
  },
  destroyed() {
    sessionStorage.removeItem('formData')
  },
  created() {
    let process = this.form.SchemeContent
    if (!process) {
      process = {
        id: "root",
        parentId: null,
        type: "ROOT",
        name: "发起人",
        desc: "任何人",
        props: {
          assignedUser: [],
          formPerms: []
        },
        children: {}
      }
    } else {
      process = JSON.parse(process)
    }
    //初始化数据
    this.$store.commit('loadForm', {
      formId: null,
      formName: "未命名表单",
      logo: {
        icon: "el-icon-eleme",
        background: "#1e90ff"
      },
      settings: {
        commiter: [],
        admin: [],
        sign: false,
        notify: {
          types: ["APP"],
          title: "消息通知标题"
        }
      },
      groupId: undefined,
      //表单
      formItems: [],
      remark: "备注说明",
      process
    })
  },
  components: {ProcessDesign},
  mounted() {
    this.getCondition()
  },
  methods: {
    getCondition() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/scheme/formkeylist",
        data: {key: self.$route.query.key},
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$store.commit('getFormConditionList', AppendData)
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    save() {
      let process = this.$store.state.design.process
      if (process && this.checkProcess(process)) {
        this.form.SchemeContent = JSON.stringify(process)
        console.log('save',process)
        if (this.id) {
          this.update()
        } else {
          this.add()
        }
      }
    },
    checkProcess(node) {
      if ((node.type == 'CC' || node.type == 'APPROVAL') && node.props.assignedUser && node.props.assignedUser.length == 0) {
        this.$message.warning(`请选择${node.name}`)
        return false;
      }

      if (node.children && !this.checkProcess(node.children)) {
        return false;
      }

      if (node.branchs && Array.isArray(node.branchs)) {
        for (let branch of node.branchs) {
          if (branch.children && !this.checkProcess(branch.children)) {
            return false;
          }
        }
      }
      return true;
    },
    update() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/scheme/update",
        data: self.form,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    add() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/scheme/add",
        data: self.form,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.top {
  margin-top: 20px;
  text-align: right;
  padding: 0 20px;
  position: absolute;
  top: 0;
  right: 20px;
}
</style>